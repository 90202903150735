import React, { useEffect, useState } from 'react';

import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import { Menu } from '../main/Menu';



const Router = () => {

	return (
		<div className='image-bg'>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Menu />}></Route>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</BrowserRouter>
		</div>
	)
}
export default Router